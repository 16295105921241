@charset "UTF-8";
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
#top .p-slider__img img, .c-topKV__heading img {
  display: block;
  width: 100%;
  line-height: 0;
}

/*
ユーティリティ系おまとめファイル
*/
.u-hover--shadow {
  transition: all 0.2s ease !important;
}
.u-hover--shadow:hover {
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.2);
}
.u-hover--underline > span {
  display: inline-block;
  position: relative;
}
.u-hover--underline > span::after {
  content: "";
  width: 0%;
  height: 2px;
  display: block;
  background-color: #2347b5;
  position: absolute;
  left: 0;
  bottom: -3px;
  transition: all 0.2s ease !important;
}
@media screen and (max-width: 1024px) {
  .u-hover--underline > span::after {
    content: none;
  }
}
.u-hover--underline:hover > span::after {
  width: 100%;
}

.u-pt0 {
  padding-top: 0px !important;
}
.u-pb0 {
  padding-bottom: 0px !important;
}
.u-mt0 {
  margin-top: 0px !important;
}
.u-mb0 {
  margin-bottom: 0px !important;
}
.u-pt5 {
  padding-top: 5px !important;
}
.u-pb5 {
  padding-bottom: 5px !important;
}
.u-mt5 {
  margin-top: 5px !important;
}
.u-mb5 {
  margin-bottom: 5px !important;
}
.u-pt10 {
  padding-top: 10px !important;
}
.u-pb10 {
  padding-bottom: 10px !important;
}
.u-mt10 {
  margin-top: 10px !important;
}
.u-mb10 {
  margin-bottom: 10px !important;
}
.u-pt15 {
  padding-top: 15px !important;
}
.u-pb15 {
  padding-bottom: 15px !important;
}
.u-mt15 {
  margin-top: 15px !important;
}
.u-mb15 {
  margin-bottom: 15px !important;
}
.u-pt20 {
  padding-top: 20px !important;
}
.u-pb20 {
  padding-bottom: 20px !important;
}
.u-mt20 {
  margin-top: 20px !important;
}
.u-mb20 {
  margin-bottom: 20px !important;
}
.u-pt25 {
  padding-top: 25px !important;
}
.u-pb25 {
  padding-bottom: 25px !important;
}
.u-mt25 {
  margin-top: 25px !important;
}
.u-mb25 {
  margin-bottom: 25px !important;
}
.u-pt30 {
  padding-top: 30px !important;
}
.u-pb30 {
  padding-bottom: 30px !important;
}
.u-mt30 {
  margin-top: 30px !important;
}
.u-mb30 {
  margin-bottom: 30px !important;
}
.u-pt35 {
  padding-top: 35px !important;
}
.u-pb35 {
  padding-bottom: 35px !important;
}
.u-mt35 {
  margin-top: 35px !important;
}
.u-mb35 {
  margin-bottom: 35px !important;
}
.u-pt40 {
  padding-top: 40px !important;
}
.u-pb40 {
  padding-bottom: 40px !important;
}
.u-mt40 {
  margin-top: 40px !important;
}
.u-mb40 {
  margin-bottom: 40px !important;
}
.u-pt45 {
  padding-top: 45px !important;
}
.u-pb45 {
  padding-bottom: 45px !important;
}
.u-mt45 {
  margin-top: 45px !important;
}
.u-mb45 {
  margin-bottom: 45px !important;
}
.u-pt50 {
  padding-top: 50px !important;
}
.u-pb50 {
  padding-bottom: 50px !important;
}
.u-mt50 {
  margin-top: 50px !important;
}
.u-mb50 {
  margin-bottom: 50px !important;
}
.u-pt55 {
  padding-top: 55px !important;
}
.u-pb55 {
  padding-bottom: 55px !important;
}
.u-mt55 {
  margin-top: 55px !important;
}
.u-mb55 {
  margin-bottom: 55px !important;
}
.u-pt60 {
  padding-top: 60px !important;
}
.u-pb60 {
  padding-bottom: 60px !important;
}
.u-mt60 {
  margin-top: 60px !important;
}
.u-mb60 {
  margin-bottom: 60px !important;
}
.u-pt65 {
  padding-top: 65px !important;
}
.u-pb65 {
  padding-bottom: 65px !important;
}
.u-mt65 {
  margin-top: 65px !important;
}
.u-mb65 {
  margin-bottom: 65px !important;
}
.u-pt70 {
  padding-top: 70px !important;
}
.u-pb70 {
  padding-bottom: 70px !important;
}
.u-mt70 {
  margin-top: 70px !important;
}
.u-mb70 {
  margin-bottom: 70px !important;
}
.u-pt75 {
  padding-top: 75px !important;
}
.u-pb75 {
  padding-bottom: 75px !important;
}
.u-mt75 {
  margin-top: 75px !important;
}
.u-mb75 {
  margin-bottom: 75px !important;
}
.u-pt80 {
  padding-top: 80px !important;
}
.u-pb80 {
  padding-bottom: 80px !important;
}
.u-mt80 {
  margin-top: 80px !important;
}
.u-mb80 {
  margin-bottom: 80px !important;
}
.u-pt85 {
  padding-top: 85px !important;
}
.u-pb85 {
  padding-bottom: 85px !important;
}
.u-mt85 {
  margin-top: 85px !important;
}
.u-mb85 {
  margin-bottom: 85px !important;
}
.u-pt90 {
  padding-top: 90px !important;
}
.u-pb90 {
  padding-bottom: 90px !important;
}
.u-mt90 {
  margin-top: 90px !important;
}
.u-mb90 {
  margin-bottom: 90px !important;
}
.u-pt95 {
  padding-top: 95px !important;
}
.u-pb95 {
  padding-bottom: 95px !important;
}
.u-mt95 {
  margin-top: 95px !important;
}
.u-mb95 {
  margin-bottom: 95px !important;
}
.u-pt100 {
  padding-top: 100px !important;
}
.u-pb100 {
  padding-bottom: 100px !important;
}
.u-mt100 {
  margin-top: 100px !important;
}
.u-mb100 {
  margin-bottom: 100px !important;
}

@media screen and (max-width: 1024px) {
  .u-md-pt0 {
    padding-top: 0px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pb0 {
    padding-bottom: 0px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mt0 {
    margin-top: 0px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mb0 {
    margin-bottom: 0px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pt5 {
    padding-top: 5px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pb5 {
    padding-bottom: 5px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mt5 {
    margin-top: 5px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mb5 {
    margin-bottom: 5px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pt10 {
    padding-top: 10px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pb10 {
    padding-bottom: 10px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mt10 {
    margin-top: 10px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mb10 {
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pt15 {
    padding-top: 15px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pb15 {
    padding-bottom: 15px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mt15 {
    margin-top: 15px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mb15 {
    margin-bottom: 15px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pt20 {
    padding-top: 20px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pb20 {
    padding-bottom: 20px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mt20 {
    margin-top: 20px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mb20 {
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pt25 {
    padding-top: 25px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pb25 {
    padding-bottom: 25px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mt25 {
    margin-top: 25px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mb25 {
    margin-bottom: 25px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pt30 {
    padding-top: 30px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pb30 {
    padding-bottom: 30px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mt30 {
    margin-top: 30px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mb30 {
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pt35 {
    padding-top: 35px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pb35 {
    padding-bottom: 35px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mt35 {
    margin-top: 35px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mb35 {
    margin-bottom: 35px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pt40 {
    padding-top: 40px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pb40 {
    padding-bottom: 40px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mt40 {
    margin-top: 40px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mb40 {
    margin-bottom: 40px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pt45 {
    padding-top: 45px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pb45 {
    padding-bottom: 45px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mt45 {
    margin-top: 45px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mb45 {
    margin-bottom: 45px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pt50 {
    padding-top: 50px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pb50 {
    padding-bottom: 50px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mt50 {
    margin-top: 50px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mb50 {
    margin-bottom: 50px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pt55 {
    padding-top: 55px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pb55 {
    padding-bottom: 55px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mt55 {
    margin-top: 55px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mb55 {
    margin-bottom: 55px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pt60 {
    padding-top: 60px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pb60 {
    padding-bottom: 60px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mt60 {
    margin-top: 60px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mb60 {
    margin-bottom: 60px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pt65 {
    padding-top: 65px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pb65 {
    padding-bottom: 65px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mt65 {
    margin-top: 65px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mb65 {
    margin-bottom: 65px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pt70 {
    padding-top: 70px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pb70 {
    padding-bottom: 70px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mt70 {
    margin-top: 70px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mb70 {
    margin-bottom: 70px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pt75 {
    padding-top: 75px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pb75 {
    padding-bottom: 75px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mt75 {
    margin-top: 75px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mb75 {
    margin-bottom: 75px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pt80 {
    padding-top: 80px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pb80 {
    padding-bottom: 80px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mt80 {
    margin-top: 80px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mb80 {
    margin-bottom: 80px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pt85 {
    padding-top: 85px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pb85 {
    padding-bottom: 85px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mt85 {
    margin-top: 85px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mb85 {
    margin-bottom: 85px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pt90 {
    padding-top: 90px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pb90 {
    padding-bottom: 90px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mt90 {
    margin-top: 90px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mb90 {
    margin-bottom: 90px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pt95 {
    padding-top: 95px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pb95 {
    padding-bottom: 95px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mt95 {
    margin-top: 95px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mb95 {
    margin-bottom: 95px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pt100 {
    padding-top: 100px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-pb100 {
    padding-bottom: 100px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mt100 {
    margin-top: 100px !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-md-mb100 {
    margin-bottom: 100px !important;
  }
}

@media screen and (max-width: 768px) {
  .u-sm-pt0 {
    padding-top: 0px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pb0 {
    padding-bottom: 0px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mt0 {
    margin-top: 0px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mb0 {
    margin-bottom: 0px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pt5 {
    padding-top: 5px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pb5 {
    padding-bottom: 5px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mt5 {
    margin-top: 5px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mb5 {
    margin-bottom: 5px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pt10 {
    padding-top: 10px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pb10 {
    padding-bottom: 10px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mt10 {
    margin-top: 10px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mb10 {
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pt15 {
    padding-top: 15px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pb15 {
    padding-bottom: 15px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mt15 {
    margin-top: 15px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mb15 {
    margin-bottom: 15px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pt20 {
    padding-top: 20px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pb20 {
    padding-bottom: 20px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mt20 {
    margin-top: 20px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mb20 {
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pt25 {
    padding-top: 25px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pb25 {
    padding-bottom: 25px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mt25 {
    margin-top: 25px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mb25 {
    margin-bottom: 25px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pt30 {
    padding-top: 30px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pb30 {
    padding-bottom: 30px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mt30 {
    margin-top: 30px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mb30 {
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pt35 {
    padding-top: 35px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pb35 {
    padding-bottom: 35px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mt35 {
    margin-top: 35px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mb35 {
    margin-bottom: 35px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pt40 {
    padding-top: 40px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pb40 {
    padding-bottom: 40px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mt40 {
    margin-top: 40px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mb40 {
    margin-bottom: 40px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pt45 {
    padding-top: 45px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pb45 {
    padding-bottom: 45px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mt45 {
    margin-top: 45px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mb45 {
    margin-bottom: 45px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pt50 {
    padding-top: 50px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pb50 {
    padding-bottom: 50px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mt50 {
    margin-top: 50px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mb50 {
    margin-bottom: 50px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pt55 {
    padding-top: 55px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pb55 {
    padding-bottom: 55px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mt55 {
    margin-top: 55px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mb55 {
    margin-bottom: 55px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pt60 {
    padding-top: 60px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pb60 {
    padding-bottom: 60px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mt60 {
    margin-top: 60px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mb60 {
    margin-bottom: 60px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pt65 {
    padding-top: 65px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pb65 {
    padding-bottom: 65px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mt65 {
    margin-top: 65px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mb65 {
    margin-bottom: 65px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pt70 {
    padding-top: 70px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pb70 {
    padding-bottom: 70px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mt70 {
    margin-top: 70px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mb70 {
    margin-bottom: 70px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pt75 {
    padding-top: 75px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pb75 {
    padding-bottom: 75px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mt75 {
    margin-top: 75px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mb75 {
    margin-bottom: 75px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pt80 {
    padding-top: 80px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pb80 {
    padding-bottom: 80px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mt80 {
    margin-top: 80px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mb80 {
    margin-bottom: 80px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pt85 {
    padding-top: 85px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pb85 {
    padding-bottom: 85px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mt85 {
    margin-top: 85px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mb85 {
    margin-bottom: 85px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pt90 {
    padding-top: 90px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pb90 {
    padding-bottom: 90px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mt90 {
    margin-top: 90px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mb90 {
    margin-bottom: 90px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pt95 {
    padding-top: 95px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pb95 {
    padding-bottom: 95px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mt95 {
    margin-top: 95px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mb95 {
    margin-bottom: 95px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pt100 {
    padding-top: 100px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-pb100 {
    padding-bottom: 100px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mt100 {
    margin-top: 100px !important;
  }
}
@media screen and (max-width: 768px) {
  .u-sm-mb100 {
    margin-bottom: 100px !important;
  }
}

@media screen and (max-width: 568px) {
  .u-xs-pt0 {
    padding-top: 0px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pb0 {
    padding-bottom: 0px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mt0 {
    margin-top: 0px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mb0 {
    margin-bottom: 0px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pt5 {
    padding-top: 5px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pb5 {
    padding-bottom: 5px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mt5 {
    margin-top: 5px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mb5 {
    margin-bottom: 5px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pt10 {
    padding-top: 10px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pb10 {
    padding-bottom: 10px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mt10 {
    margin-top: 10px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mb10 {
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pt15 {
    padding-top: 15px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pb15 {
    padding-bottom: 15px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mt15 {
    margin-top: 15px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mb15 {
    margin-bottom: 15px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pt20 {
    padding-top: 20px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pb20 {
    padding-bottom: 20px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mt20 {
    margin-top: 20px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mb20 {
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pt25 {
    padding-top: 25px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pb25 {
    padding-bottom: 25px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mt25 {
    margin-top: 25px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mb25 {
    margin-bottom: 25px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pt30 {
    padding-top: 30px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pb30 {
    padding-bottom: 30px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mt30 {
    margin-top: 30px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mb30 {
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pt35 {
    padding-top: 35px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pb35 {
    padding-bottom: 35px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mt35 {
    margin-top: 35px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mb35 {
    margin-bottom: 35px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pt40 {
    padding-top: 40px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pb40 {
    padding-bottom: 40px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mt40 {
    margin-top: 40px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mb40 {
    margin-bottom: 40px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pt45 {
    padding-top: 45px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pb45 {
    padding-bottom: 45px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mt45 {
    margin-top: 45px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mb45 {
    margin-bottom: 45px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pt50 {
    padding-top: 50px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pb50 {
    padding-bottom: 50px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mt50 {
    margin-top: 50px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mb50 {
    margin-bottom: 50px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pt55 {
    padding-top: 55px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pb55 {
    padding-bottom: 55px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mt55 {
    margin-top: 55px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mb55 {
    margin-bottom: 55px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pt60 {
    padding-top: 60px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pb60 {
    padding-bottom: 60px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mt60 {
    margin-top: 60px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mb60 {
    margin-bottom: 60px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pt65 {
    padding-top: 65px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pb65 {
    padding-bottom: 65px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mt65 {
    margin-top: 65px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mb65 {
    margin-bottom: 65px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pt70 {
    padding-top: 70px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pb70 {
    padding-bottom: 70px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mt70 {
    margin-top: 70px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mb70 {
    margin-bottom: 70px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pt75 {
    padding-top: 75px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pb75 {
    padding-bottom: 75px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mt75 {
    margin-top: 75px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mb75 {
    margin-bottom: 75px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pt80 {
    padding-top: 80px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pb80 {
    padding-bottom: 80px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mt80 {
    margin-top: 80px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mb80 {
    margin-bottom: 80px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pt85 {
    padding-top: 85px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pb85 {
    padding-bottom: 85px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mt85 {
    margin-top: 85px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mb85 {
    margin-bottom: 85px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pt90 {
    padding-top: 90px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pb90 {
    padding-bottom: 90px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mt90 {
    margin-top: 90px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mb90 {
    margin-bottom: 90px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pt95 {
    padding-top: 95px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pb95 {
    padding-bottom: 95px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mt95 {
    margin-top: 95px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mb95 {
    margin-bottom: 95px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pt100 {
    padding-top: 100px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-pb100 {
    padding-bottom: 100px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mt100 {
    margin-top: 100px !important;
  }
}
@media screen and (max-width: 568px) {
  .u-xs-mb100 {
    margin-bottom: 100px !important;
  }
}

.u-bg--white {
  background-color: #FFFFFF;
}

.u-lineHeight1 {
  line-height: 1;
}

.u-txt-bdr {
  padding: 0 5px;
}
.u-txt-bdr--keyColor {
  background: linear-gradient(transparent 60%, #26366C 60%);
}
.u-txt-bdr--yellow {
  background: linear-gradient(transparent 60%, #FFEF69 60%);
}
.u-txt--bold {
  font-weight: 600;
}
.u-txt--keyColor {
  color: #26366C;
}
.u-txt--red {
  color: #c82e2e;
}
.u-txt--white {
  color: #FFFFFF;
}
.u-txt--size20 {
  font-size: 20px;
}
.u-txt--size18 {
  font-size: 18px;
}
.u-txt--size15 {
  font-size: 15px;
}

.u-taL {
  text-align: left !important;
}

.u-taC {
  text-align: center !important;
}
@media screen and (max-width: 568px) {
  .u-taC--xs-taL {
    text-align: left !important;
  }
}
@media screen and (max-width: 768px) {
  .u-taC--sm-taL {
    text-align: left !important;
  }
}
@media screen and (max-width: 1024px) {
  .u-taC--md-taL {
    text-align: left !important;
  }
}
@media screen and (max-width: 1280px) {
  .u-taC--lg-taL {
    text-align: left !important;
  }
}

.u-taR {
  text-align: right !important;
}

.u-only-xs {
  display: none !important;
}
@media screen and (max-width: 568px) {
  .u-only-xs {
    display: block !important;
  }
}

.u-only-sm {
  display: none !important;
}
@media screen and (max-width: 768px) {
  .u-only-sm {
    display: block !important;
  }
}
@media screen and (max-width: 568px) {
  .u-only-sm {
    display: none !important;
  }
}

.u-only-md {
  display: none !important;
}
@media screen and (max-width: 1024px) {
  .u-only-md {
    display: block !important;
  }
}
@media screen and (max-width: 768px) {
  .u-only-md {
    display: none !important;
  }
}

@media screen and (max-width: 1024px) {
  .u-only-lg {
    display: none !important;
  }
}

.u-under-md {
  display: none !important;
}
@media screen and (max-width: 1024px) {
  .u-under-md {
    display: block !important;
  }
}

.u-under-iPadPro-v {
  display: none !important;
}
@media screen and (max-width: 834px) {
  .u-under-iPadPro-v {
    display: block !important;
  }
}

.u-under-sm {
  display: none !important;
}
@media screen and (max-width: 768px) {
  .u-under-sm {
    display: block !important;
  }
}

.u-not-under-xs {
  display: block !important;
}
@media screen and (max-width: 568px) {
  .u-not-under-xs {
    display: none !important;
  }
}

.u-not-under-sm {
  display: block !important;
}
@media screen and (max-width: 768px) {
  .u-not-under-sm {
    display: none !important;
  }
}

.u-not-under-bp1310 {
  display: block !important;
}
@media screen and (max-width: 1310px) {
  .u-not-under-bp1310 {
    display: none !important;
  }
}

.u-not-only-sm {
  display: block !important;
}
@media screen and (max-width: 768px) {
  .u-not-only-sm {
    display: none !important;
  }
}
@media screen and (max-width: 568px) {
  .u-not-only-sm {
    display: block !important;
  }
}

.u-overflow-hidden {
  overflow: hidden !important;
}

/*
ユーティリティ系おまとめファイル
*/
.c-topKV {
  position: relative;
  width: 100%;
  height: auto;
  background-color: #FFFFFF;
  background: linear-gradient(to right, #CBBD5E 45%, #FFFFFF 45%);
  aspect-ratio: 1440/679;
  max-height: 679px;
}
@media screen and (min-width: 1440px) {
  .c-topKV {
    aspect-ratio: auto;
  }
}
@media screen and (max-width: 900px) {
  .c-topKV {
    background: #FFFFFF;
    max-height: none;
    aspect-ratio: auto;
  }
}
.c-topKV__lineDeco {
  max-width: 100%;
  width: 1468px;
  height: 0px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
.c-topKV__lineDeco::before, .c-topKV__lineDeco::after {
  content: "";
  display: inline-block;
  width: 235px;
  height: 2px;
  display: inline-block;
  position: absolute;
  background-color: #26366C;
  transform: rotate(110deg);
  pointer-events: none;
}
@media screen and (max-width: 1200px ) {
  .c-topKV__lineDeco::before, .c-topKV__lineDeco::after {
    width: 180px;
  }
}
@media screen and (max-width: 900px) {
  .c-topKV__lineDeco::before, .c-topKV__lineDeco::after {
    width: 150px;
  }
}
.c-topKV__lineDeco::before {
  top: 50px;
  left: -3%;
}
@media screen and (max-width: 900px) {
  .c-topKV__lineDeco::before {
    content: none;
  }
}
.c-topKV__lineDeco::after {
  top: -40px;
  right: 1%;
}
@media screen and (max-width: 900px) {
  .c-topKV__lineDeco::after {
    top: -10px;
    right: -25px;
  }
}
.c-topKV__container {
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.c-topKV__inner {
  position: relative;
  width: 1468px;
  height: 100%;
  margin: 0 auto;
}
@media screen and (max-width: 1440px) {
  .c-topKV__inner {
    max-width: 1440px;
    width: 100%;
  }
}
@media screen and (max-width: 1280px) {
  .c-topKV__inner {
    max-width: 1280px;
  }
}
.c-topKV__movie {
  position: relative;
  width: 77%;
  height: 100%;
  line-height: 0;
  margin-left: auto;
  background-color: #FFFFFF;
}
@media screen and (max-width: 900px) {
  .c-topKV__movie {
    width: 100%;
    height: 406px;
  }
}
.c-topKV__movie video {
  opacity: 0.99;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center right;
  font-family: "object-fit: cover; object-position: center right;";
  pointer-events: none;
}
@media screen and (max-width: 900px) {
  .c-topKV__movie video {
    object-position: center bottom;
    font-family: "object-fit: cover; object-position: center bottom;";
  }
}
.c-topKV__body {
  width: 476px;
  height: 100%;
  background-color: #FFFFFF;
  position: absolute;
  top: 0;
  left: 174px;
  z-index: 2;
  transform: skew(340deg);
}
@media screen and (max-width: 1200px ) {
  .c-topKV__body {
    width: 400px;
    left: 130px;
  }
}
@media screen and (max-width: 900px) {
  .c-topKV__body {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    width: 100%;
  }
}
@media screen and (max-width: 900px) {
  .c-topKV__body::before {
    content: "";
    display: block;
    opacity: 0.77;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 580px 0 0 188px;
    border-color: transparent transparent transparent #CBBD5E;
  }
}
@media screen and (max-width: 568px) {
  .c-topKV__body::before {
    border-width: 520px 0 0 188px;
  }
}
@media screen and (max-width: 900px) {
  .c-topKV__body::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 116px 38px;
    border-color: transparent transparent #26366C transparent;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
.c-topKV__body-inner {
  transform: skew(20deg);
  max-width: 360px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
@media screen and (max-width: 1200px ) {
  .c-topKV__body-inner {
    max-width: 280px;
  }
}
@media screen and (max-width: 900px) {
  .c-topKV__body-inner {
    position: relative;
    z-index: 3;
    transform: none;
    max-width: 100%;
    padding: 50px 28px 90px;
  }
}
.c-topKV__heading {
  max-width: 268px;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}
@media screen and (max-width: 1200px ) {
  .c-topKV__heading {
    max-width: 230px;
  }
}
@media screen and (max-width: 900px) {
  .c-topKV__heading {
    max-width: 300px;
  }
}
@media screen and (max-width: 568px) {
  .c-topKV__heading {
    max-width: 203px;
  }
}
.c-topKV__heading--en {
  max-width: initial;
}
@media screen and (max-width: 900px) {
  .c-topKV__heading--en {
    max-width: 360px;
  }
}
.c-topKV__text {
  animation-name: text_animation;
  animation-duration: 2.5s;
  animation-fill-mode: both;
  animation-delay: 1.5s;
}
.c-topKV__text--1 {
  animation-delay: calc(1s + (1s / 10));
}
.c-topKV__text--2 {
  animation-delay: calc(1s + (2s / 10));
}
.c-topKV__text--3 {
  animation-delay: calc(1s + (3s / 10));
}
.c-topKV__text--4 {
  animation-delay: calc(1s + (4s / 10));
}
.c-topKV__text--5 {
  animation-delay: calc(1s + (5s / 10));
}
.c-topKV__text--6 {
  animation-delay: calc(1s + (6s / 10));
}
.c-topKV__text--7 {
  animation-delay: calc(1s + (7s / 10));
}
.c-topKV__text--8 {
  animation-delay: calc(1s + (8s / 10));
}
.c-topKV__text--9 {
  animation-delay: calc(1s + (9s / 10));
}
.c-topKV__text--10 {
  animation-delay: calc(1s + (10s / 10));
}
.c-topKV__text--11 {
  animation-delay: calc(1s + (11s / 10));
}
.c-topKV__desc {
  font-size: 16px;
  font-weight: 600;
  color: #26366C;
  animation-name: text_animation;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-delay: 2.9s;
}
.c-topKV__rectLeft {
  width: 450px;
  height: 100%;
  background: #26366C;
  position: absolute;
  top: 0;
  left: 41px;
  z-index: 1;
  transform: skew(20deg);
  clip-path: polygon(0 0, 100% 0, 75% 100%, 0% 100%);
  animation-name: leftDeco_slide;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-delay: 0.2s;
}
@media screen and (max-width: 1200px ) {
  .c-topKV__rectLeft {
    clip-path: polygon(0 0, 100% 0, 60% 100%, 0% 100%);
  }
}
@media screen and (max-width: 900px) {
  .c-topKV__rectLeft {
    background-color: transparent;
    clip-path: none;
    transform: none;
    top: 0;
    left: -100%;
    width: 0 !important;
    height: 0;
    border-style: solid;
    border-width: 256px 84px 0 0;
    border-color: #26366C transparent transparent transparent;
    animation: none;
    transition: left 1s;
  }
}
@media screen and (max-width: 900px) {
  .c-topKV__rectLeft.is-left_animation_changePoint {
    left: 0;
  }
}
.c-topKV__overlayLeft {
  width: 600px;
  height: 100%;
  opacity: 0.77;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: #CBBD5E;
  position: absolute;
  top: 0;
  left: -558px;
  z-index: 2;
  transform: skew(20deg);
}
@media screen and (max-width: 900px) {
  .c-topKV__overlayLeft {
    display: none;
  }
}
.c-topKV__smallRect {
  width: 132px;
  height: 201px;
  display: block;
  background-color: #FFFFFF;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  bottom: 0;
  z-index: 0;
  transform: skew(20deg);
  left: 10%;
  transition: opacity 1s, left 1s;
}
@media screen and (max-width: 900px) {
  .c-topKV__smallRect {
    display: none;
  }
}
.c-topKV__smallRect.is-left_animation {
  left: 460px;
  opacity: 0.8;
}
@media screen and (max-width: 1200px ) {
  .c-topKV__smallRect.is-left_animation {
    left: 380px;
  }
}
.c-topKV__parallelRight {
  width: 662px;
  height: 100%;
  position: absolute;
  top: 0;
  right: -538px;
  z-index: 2;
}
@media screen and (max-width: 1440px) {
  .c-topKV__parallelRight {
    right: -580px;
  }
}
@media screen and (max-width: 900px) {
  .c-topKV__parallelRight {
    right: -100%;
    width: 0;
    height: 0;
    opacity: 0.44;
    border-style: solid;
    border-width: 0 112px 280px 0;
    border-color: transparent #CBBD5E transparent transparent;
    transition: right 1s;
  }
}
@media screen and (max-width: 900px) {
  .c-topKV__parallelRight.is-right_animation_changePoint {
    right: 0;
  }
}
.c-topKV__parallelRight-above {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  transform: skew(340deg);
  border-left: 3px solid #26366C;
  z-index: 2;
}
@media screen and (max-width: 900px) {
  .c-topKV__parallelRight-above {
    display: none;
  }
}
.c-topKV__parallelRight-bottom {
  position: relative;
  width: 50%;
  height: 100%;
  background-color: #CBBD5E;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
  transform: skew(20deg);
  left: 36%;
  opacity: 0;
  transition: opacity 1s, left 1s;
}
@media screen and (max-width: 900px) {
  .c-topKV__parallelRight-bottom {
    display: none;
  }
}
.c-topKV__parallelRight-bottom.is-right_animation {
  left: 0;
  opacity: 0.44;
}

@keyframes text_animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes leftDeco_slide {
  0% {
    width: 0px;
  }
  100% {
    width: 450px;
  }
}
#top #wrapper {
  padding: 0 0;
}
#top .p-slider__img {
  background-color: #FFFFFF;
}
#top .p-slider__container {
  width: 100%;
}
#top .p-container {
  overflow: hidden;
}
#top .p-container__inner {
  display: flex;
  align-items: flex-end;
  margin: 0 -79.5px;
}
@media screen and (max-width: 1200px ) {
  #top .p-container__inner {
    margin: 0 -40px;
  }
}
@media screen and (max-width: 900px) {
  #top .p-container__inner {
    flex-direction: column;
    margin: 0;
  }
}
#top .p-container__block {
  flex-grow: 1;
  width: 100%;
  padding: 110px 79.5px 43px;
}
@media screen and (max-width: 1200px ) {
  #top .p-container__block {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media screen and (max-width: 900px) {
  #top .p-container__block {
    padding: 60px 0;
  }
}
#top .p-container__block--main {
  max-width: 100%;
  border-right: 1px solid #26366C;
}
@media screen and (max-width: 900px) {
  #top .p-container__block--main {
    border-right: none;
    border-bottom: 1px solid #26366C;
  }
}
#top .p-container__block--sub {
  max-width: 521px;
  flex-grow: 0;
  flex-shrink: 0;
}
@media screen and (max-width: 1200px ) {
  #top .p-container__block--sub {
    max-width: 440px;
  }
}
@media screen and (max-width: 1024px) {
  #top .p-container__block--sub {
    max-width: 415px;
  }
}
@media screen and (max-width: 900px) {
  #top .p-container__block--sub {
    max-width: 100%;
  }
}
#top .p-vals__list {
  margin-bottom: 31.5px;
}
#top .p-vals__item {
  text-align: center;
  border-bottom: 1px solid #26366C;
  padding: 0 5px;
  color: #26366C;
}
@media screen and (max-width: 900px) {
  #top .p-vals__item {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
#top .p-vals__num {
  display: inline-block;
  font-size: 30px;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
}
@media screen and (max-width: 1024px) {
  #top .p-vals__num {
    font-size: 26px;
  }
}
#top .p-vals__num--medium {
  font-size: 34px;
}
@media screen and (max-width: 1024px) {
  #top .p-vals__num--medium {
    font-size: 30px;
  }
}
#top .p-vals__num--large {
  font-size: 51px;
}
@media screen and (max-width: 1024px) {
  #top .p-vals__num--large {
    font-size: 45px;
  }
}
#top .p-vals__unit {
  display: inline-block;
  font-size: 14px;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
}
#top .p-vals__unit--large {
  font-size: 25px;
}
#top .p-vals__date {
  font-size: 16px;
  font-weight: 500;
}
#top .p-vals__date-year {
  display: inline-block;
  margin-right: 7px;
}
#top .p-vals__date-month {
  display: inline-block;
}
#top .p-vals__date-unit {
  position: relative;
  top: -1px;
}
#top .p-vals__power {
  font-size: 25px;
}
#top .p-vals__block {
  display: flex;
  align-items: baseline;
  justify-content: center;
  padding: 15px 0;
}
#top .p-vals__block-title {
  font-size: 15px;
  font-weight: 500;
  margin-right: 5px;
}
@media screen and (max-width: 1024px) {
  #top .p-vals__block-title {
    font-size: 14px;
  }
}
#top .p-vals__block-unit {
  margin-left: 5px;
}
#top .p-vals__btns {
  max-width: 215px;
  width: 100%;
  margin: 0 auto;
}
#top .p-vision__desc {
  font-size: 26px;
  font-weight: 600;
  line-height: 1.85;
  text-align: center;
  color: #26366C;
  margin-bottom: 50px;
}
@media screen and (max-width: 568px) {
  #top .p-vision__desc {
    font-size: 18px;
    margin-bottom: 30px;
  }
}
#top .p-business__list {
  margin-bottom: -55px;
}
@media screen and (max-width: 1024px) {
  #top .p-business__list {
    margin-bottom: -30px;
  }
}
#top .p-business__item {
  margin-bottom: 13px;
}
#top .p-company__wrap {
  max-width: 1016px;
  width: 100%;
  margin: 0 auto;
}
#top .p-recruit {
  width: 100%;
  height: 597px;
  background-color: #F2F2F2;
}
@media screen and (max-width: 1024px) {
  #top .p-recruit {
    height: 450px;
  }
}
@media screen and (max-width: 900px) {
  #top .p-recruit {
    height: auto;
  }
}
#top .p-recruit__container {
  display: flex;
  width: 100%;
  height: 100%;
  max-width: calc(100% - (100% - 1170px) / 2);
  margin-left: auto;
}
@media screen and (max-width: 1200px ) {
  #top .p-recruit__container {
    padding-left: 30px;
  }
}
@media screen and (max-width: 900px) {
  #top .p-recruit__container {
    flex-direction: column;
    max-width: 100%;
    padding-left: 0;
  }
}
#top .p-recruit__body {
  width: 100%;
  height: 100%;
  max-width: 489px;
  display: flex;
  align-items: center;
  padding-right: 20px;
}
@media screen and (max-width: 1200px ) {
  #top .p-recruit__body {
    max-width: 400px;
  }
}
@media screen and (max-width: 1024px) {
  #top .p-recruit__body {
    max-width: 350px;
  }
}
@media screen and (max-width: 900px) {
  #top .p-recruit__body {
    padding-right: 0;
    max-width: 100%;
    padding: 30px 30px;
  }
}
@media screen and (max-width: 768px) {
  #top .p-recruit__body {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 568px) {
  #top .p-recruit__body {
    padding-left: 15px;
    padding-right: 15px;
  }
}
#top .p-recruit__inner {
  width: 100%;
}
#top .p-recruit__desc {
  font-size: 25px;
  font-weight: 600;
  font-family: 游ゴシック体, YuGothic-M, "Yu Gothic", YuGothic, "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo;
  color: #26366C;
}
@media screen and (max-width: 1200px ) {
  #top .p-recruit__desc {
    font-size: 20px;
  }
}
@media screen and (max-width: 768px) {
  #top .p-recruit__desc {
    font-size: 18px;
  }
}
#top .p-recruit__gallery {
  width: 100%;
  height: 100%;
  display: flex;
}
#top .p-recruit__pic {
  width: 33.3333333333%;
  height: 100%;
}
#top .p-recruit__pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;";
  line-height: 1;
}
@media screen and (max-width: 900px) {
  #top .p-recruit__pic img {
    height: 274px;
  }
}